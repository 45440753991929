<template>
  <div class="container mt-4">
    <h2>
      {{ $t("comments") }}
    </h2>
    <h5 class="text-muted mt-2 mb-4">{{ $t("commentsOfProduct") }}</h5>
    <div class="d-flex justify-content-center">
      <BoostrapPagination
        @change="SET_PAGINATION"
        :page="page"
        :pages="pages"
      ></BoostrapPagination>
    </div>
    <Tables
      no-search
      :headers="headers"
      :items="getComments"
      :loading="getCommentsAreLoading"
      :searchPlaceholder="$t('searchComments')"
      @change:search="SET_SEARCH"
    >
      <template #username="{ item }">
        <img
          :src="`/images/${item.isActive === 1 ? 'success' : 'danger'}.svg`"
          width="19"
          alt="status-img"
        />
        <router-link :to="`/user/${item.userId}`">{{
          item.username
        }}</router-link>
      </template>
      <template #productTitle="{ item }">
        <router-link :to="`/product/${item.productId}`">{{
          item.productTitle
        }}</router-link>
      </template>
      <template #createdAt="{ item }">
        <small>{{ formatter(new Date(item.createdAt)) }}</small>
      </template>
      <template #point="{ item }">
        <span class="text-success" v-for="i in item.point" :key="'m' + i"
          >★</span
        >
        <span v-for="i in 5 - item.point" :key="'v' + i">☆</span>
      </template>
      <template #options="{ item }">
        <button
          @click="
            $router.replace({
              query: {
                ...$route.query,
                modal: `comment-${item.id}`,
              },
            })
          "
          class="btn btn-pill donate mx-1"
        >
          {{ $t("view") }}
        </button>
      </template>
    </Tables>
    <div class="d-flex justify-content-center">
      <BoostrapPagination
        @change="SET_PAGINATION"
        :page="page"
        :pages="pages"
      ></BoostrapPagination>
    </div>
    <CommentActionModal
      :product="product"
      @refresh="GET_LIST()"
      :comments="getComments"
    ></CommentActionModal>
  </div>
</template>

<script>
import Tables from "../Tables/index.vue";
import { formatter } from "gurhan/helpers/Date";
import BoostrapPagination from "gurhan/vue/BootstrapPagination.vue";
import CommentActionModal from "../Comments/CommentActionModal.vue";
export default {
  components: { Tables, BoostrapPagination, CommentActionModal },
  props: {
    product: {
      default: () => {},
    },
  },
  data() {
    return {
      search: "",
      page: 1,
      limit: 10,
      headers: [
        {
          col: 12,
          md: 4,
          lg: 2,
          xl: 2,
          title: "commentor", //"Yorum Yapan"
          value: "username",
        },
        {
          col: 12,
          md: 8,
          lg: 2,
          xl: 2,
          title: "productName",
          value: "productTitle",
        },
        { col: 12, md: 8, lg: 7, xl: 7, title: "Yorum", value: "text" },
        { col: 12, md: 2, lg: 2, xl: 2, title: "Puan", value: "point" },
        {
          col: 12,
          md: 2,
          lg: 2,
          xl: 2,
          title: "createdDate",
          value: "createdAt",
        },
      ],
    };
  },
  computed: {
    count() {
      return this.$store.state.Comments.count;
    },
    getCommentsAreLoading() {
      return this.$store.state.Comments.commentsAreLoading;
    },
    getComments() {
      const comments = this.$store.state.Comments.commentsWithFilter.find(
        (c) => c.productId === this.product.id && c.page === this.page
      );
      if (comments) return comments.data;
      else return [];
    },
    pages() {
      const comments = this.$store.state.Comments.commentsWithFilter.find(
        (c) => c.productId === this.product.id
      );
      if (comments) return comments.count;
      else return 0;
    },
  },
  methods: {
    formatter,
    SET_PAGINATION(page) {
      this.page = page;
      this.$router.replace({ query: { ...this.$route.query, page } });
      this.GET_LIST();
    },
    SET_DATE(d) {
      const date = new Date(d);
      return formatter(date);
    },
    SET_SEARCH(search) {
      this.search = search;
      this.GET_LIST();
    },
    GET_LIST() {
      this.$store.dispatch("GET_COMMENTS_WITH_FILTER", {
        page: this.page,
        filter: `productId=${this.product.id}&`,
        limit: 10,
        key: "productId",
        dataFilter: {
          productId: this.product.id,
          userId: -1,
        },
      });
    },
  },
  mounted() {
    if (!isNaN(Number(this.$route.query.page)))
      this.page = Number(this.$route.query.page);
    this.GET_LIST();
  },
};
</script>
