<template>
  <Modal
    v-model="editComment"
    hide-footer
    ref="editCommentModal"
    title="Yorumu Düzenleyin"
    :sm="confirmRemoveComment"
  >
    <section class="vid-title-sec">
      <div class="container">
        <div
          class="alert"
          :class="`alert-${alertBox.variant}`"
          v-if="alertBox.status"
        >
          {{ alertBox.message }}
        </div>
        <div class="row">
          <div class="col-12">
            <h5>{{ $t("product") }} : {{ form.productTitle }}</h5>
            <h4 class="text-right">
              <span class="text-success">
                <span
                  class="text-success"
                  v-for="i in form.point"
                  :key="'m' + i"
                  >★</span
                >
                <span v-for="i in 5 - form.point" :key="'v' + i">☆</span>
              </span>
            </h4>
          </div>
        </div>
        <form @submit.prevent="() => false">
          <div class="vid-title">
            <h2 class="title-hd">
              <i class="icon-user"></i> {{ form.username }}
              {{ $t("toComment") }}
            </h2>
            <div class="form_field">
              <textarea v-model="form.text" />
            </div>
            <h6 class="text-right">
              <span> {{ formatter(new Date(form.createdAt)) }}</span>
            </h6>
          </div>
        </form>
        <div class="mt-4 d-flex justify-content-end">
          <button
            @click="SEND_TO_UPDATE_COMMENT(form.isActive)"
            class="btn btn-pill donate mx-1"
            :disabled="getNewCommentIsLoading"
          >
            <loading v-if="getNewCommentIsLoading"></loading>
            <span v-else>{{ $t("updateComment") }}</span>
          </button>
          <button
            v-if="form.isActive === 1"
            @click="SEND_TO_UPDATE_COMMENT(0)"
            class="btn btn-pill btn-danger mx-1"
            :disabled="getNewCommentIsLoading"
          >
            <loading v-if="getNewCommentIsLoading"></loading>
            <span v-else>{{ $t("removeComment") }}</span>
          </button>
          <button
            v-else
            @click="SEND_TO_UPDATE_COMMENT(1)"
            class="btn btn-pill donate mx-1"
            :disabled="getNewCommentIsLoading"
          >
            <loading v-if="getNewCommentIsLoading"></loading>
            <span v-else> {{ $t("postComment") }}</span>
          </button>
        </div>
      </div>
    </section></Modal
  >
</template>

<script>
import { formatter } from "gurhan/helpers/Date";
import Modal from "../Shared/Modal/index.vue";
export default {
  components: { Modal },
  data() {
    return {
      confirmRemoveComment: false,
      isLoadingRemoveComment: false,
      editComment: false,
      form: { id: -1, name: "", text: "" },
      alertBox: { status: false, message: "", variant: "success" },
    };
  },
  props: {
    comments: {
      default: () => [],
    },
    product: {
      default: () => {},
    },
  },
  computed: {
    getNewCommentIsLoading() {
      return this.$store.state.Comments.newCommentIsLoading;
    },
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.SET_CATEGORY_ACTION_FROM_QUERY();
      },
    },
    editComment(data) {
      this.alertBox.status = false;
      if (!data)
        this.$router.replace({
          query: { ...this.$route.query, modal: "closed" },
        });
    },
  },
  methods: {
    formatter,
    async SEND_TO_UPDATE_COMMENT(isActive) {
      this.alertBox = await this.$store.dispatch("SEND_UPDATE_COMMENT", {
        commentIndex: this.comments.findIndex((s) => s.id === this.form.id),
        productId: this.form.productId,
        form: {
          isActive: isActive,
          text: this.form.text,
          id: this.form.id,
        },
      });
      setTimeout(() => {
        if (this.alertBox.variant === "success") {
          this.$emit("refresh");
          this.editComment = false;
          this.$store.commit("SET_COMMENTS_ARE_LOADING", true);
          setTimeout(() => {
            this.$store.commit("SET_COMMENTS_ARE_LOADING", false);
          }, 1000);
        }
      }, 2500);
    },
    SET_CATEGORY_ACTION_FROM_QUERY() {
      if (this.$route.query.modal)
        if (this.$route.query.modal.includes("comment")) {
          const [, id] = this.$route.query.modal.split("-");
          const form = this.comments.find((s) => s.id === Number(id));
          if (form) {
            this.form = { ...form };
            this.editComment = true;
          } else this.editComment = false;
        }
    },
    async SEND_TO_REMOVE_COMMENT() {
      this.isLoadingRemoveComment = true;
      this.alertBox = await this.$store.dispatch("SEND_TO_REMOVE_COMMENT", {
        id: this.form.id,
        text: this.form.text,
        commentIndex: this.comments.findIndex((s) => s.id === this.form.id),
      });
      this.confirmRemoveComment = false;
      setTimeout(() => {
        if (this.alertBox.variant === "success")
          this.$store.commit("SET_COMMENTS_ARE_LOADING", true);
        setTimeout(() => {
          this.$store.commit("SET_COMMENTS_ARE_LOADING", false);
          this.isLoadingRemoveComment = false;
        }, 1000);
      }, 2500);
    },
  },
  created() {
    this.SET_CATEGORY_ACTION_FROM_QUERY();
  },
};
</script>
